<template>
  <div>
    <b-overlay
      v-if="onlineBankDeposit"
      :show="show"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div>
        <div>
          <b-button
            block
            :variant="$store.state.appConfig.layout.skin === 'dark' ? 'dark' : ''"
            class="d-xxl-none mb-1"
            @click="isHidden = !isHidden"
          >
            <div>
              <span class="text-black">{{ $t('guide') }}</span>
              <feather-icon
                variant="black"
                :class="$store.state.appConfig.layout.isRTL ? 'left-position' : 'right-position'"
                :icon="isHidden ? 'ChevronDownIcon' : 'ChevronUpIcon'"
                size="20"
              />
            </div>
          </b-button>
          <b-row>
            <b-col
              class="col-xl-7"
              xl="12"
              md="12"
              sm="12"
            >
              <b-card
                :class="(isHidden ? 'd-none' : '')"
                class="d-xxl-none"
                style="background-color: #f2f3f5;"
              >
                <div>
                  <feather-icon
                    icon="AlertTriangleIcon"
                    size="20"
                    class="text-warning mb-1"
                  />

                  <p>1. {{ $t('deposit-guide.desc1') }}
                  </p>
                  <p>2. {{ $t('deposit-guide.desc2') }}
                  </p>
                  <p>3. {{ $t('deposit-guide.desc3') }}
                  </p>
                  <p>4. {{ $t('forex-online-deposit-guide-desc4', { brand_name: getBrandName(),min_deposit:min_deposit,currency:currency }) }}
                  </p>
                  <p>5. {{ $t('deposit-guide.desc5') }}
                  </p>
                  <p>6. {{ $t('deposit-guide.desc6') }}
                  </p>

                </div>
              </b-card>
              <b-card
                :class="(isHidden ? 'd-none' : '')"
                class="d-xxl-none"
                style="background-color: #f2f3f5;"
              >
                <div>
                  <h5>
                    {{ $t('deposit-guide.desc7') }}
                  </h5>

                  <div class="d-flex justify-content-center mt-3">
                    <b-button
                      variant="primary"
                      :to="`/${$i18n.locale}/document/bank`"
                    >

                      {{ $t('fiatWidraw.buttontxt') }}
                    </b-button>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-card :title="$t('Deposit')">
            <validation-observer ref="simpleRules">
              <b-form class="auth-forgot-password-form mt-2">

                <b-row>
                  <b-col
                    xl="12"
                    sm="12"
                    class="col-xxl-6"
                  >
                    <b-form-group
                      :label="$t('forex.platform')"
                      label-for="platform"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('forex.platform')"
                        vid="platform"
                        rules="required"
                      >
                        <v-select
                          id="platform"
                          v-model="platformSelected"
                          :options="platform"
                          label="name"
                          :clearable="false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      :label="$t('forex.account-number')"
                      label-for="account-number"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('forex.account-number')"
                        vid="account-number"
                        rules="required"
                      >
                        <v-select
                          id="account-number"
                          v-model="accSelected"
                          :options="accounts"
                          :clearable="false"
                          label="account"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- <b-form-group
                    class="right-selector"
                    :label="$t('choose-bank-card')"
                    label-for="numbers"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('fiatDiposit.bank_card')"
                      rules="required"
                    >
                      <v-select
                        id="numbers"
                        v-model="numbers"
                        :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                        :options="bankCard"
                        :placeholder="$t('fiatWidraw.select')"
                        :clearable="false"
                        :get-option-label="(option) => option.numbers"
                        @input="gatewayList($event.currency.currency)"
                      >
                        <template #option="item">
                          <div>
                            <div>
                              <span>{{ $t('iban') }} : {{ item.ibanCurrency }}</span>
                              <br>
                              <span>{{ $t('card_numbers') }} : {{ item.numbers }}</span>
                            </div>
                          </div>
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group> -->
                    <b-form-group :label="$t('deposit-amount-based-on-currency' , { currency: currency })">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('fiatDiposit.amount')"
                        rules="required"
                      >
                        <b-form-input
                          id="Amount"
                          v-model="amount"
                          name="Amount"
                          maxlength="15"
                          :placeholder="$t('Amount')"
                          :formatter="numberFormat"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-row>

                      <b-col
                        v-for="(card, index) in cards"
                        :key="index"
                        sm="6"
                        class="my-sm-1"
                        @click="selectCard(card)"
                      >
                        <b-card
                          class="mb-0"
                          :class="card.default ? 'bg-success text-white' : 'cardSelected'"
                        >
                          <div class="d-flex justify-content-between">
                            <div
                              v-if="card.default"
                              class="checkIcon align-self-center"
                            >
                              <feather-icon
                                icon="CheckCircleIcon"
                                size="18"
                                class="text-white"
                              />
                            </div>
                            <div
                              v-else
                              class="align-self-center"
                            >
                              <feather-icon
                                icon="CircleIcon"
                                size="18"
                              />
                            </div>
                            <div class="align-self-center">
                              <h4
                                style="margin-bottom: 5px;"
                                :class="card.default ? 'text-white' : ''"
                              >
                                {{ card.nickname }}
                              </h4>
                              <span>{{ $t('fee') }}:</span>
                              <span>{{ card.commission }}</span>
                              <span>{{ card.commission_currency }}</span>
                              <br>
                              <span>{{ '(' + $t('based-on-currency') +' '+ card.currency + ')' }}</span>
                            </div>
                            <b-avatar
                              class="align-self-center"
                              size="3rem"
                            >
                              <b-img
                                :src="card.img"
                                style="width: 2rem;"
                              /></b-avatar></div>
                        </b-card>
                      </b-col>
                    </b-row>
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('select-gateway')"
                      rules="required"
                    >
                      <b-form-input
                        id="paymentGatewayName"
                        v-model="paymentGatewayName"
                        hidden
                        name="paymentGatewayName"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <b-card
                      class="mt-1"
                      style="border: 1px solid #e3e4e5;"
                    >
                      <div class="d-flex justify-content-between">
                        <span>{{ $t('deposit-fee') }}</span>
                        <span>{{ currency }} {{ commission }}</span>
                      </div>
                      <div class="mt-1 d-flex justify-content-between">
                        <h5>{{ $t('deposit-amount') }}</h5>
                        <h5>{{ (amount ? currency + ' ' + numberWithCommas(amount) : currency + ' ' + '0') }}</h5>
                      </div>
                      <hr>
                      <div class="mt-1 d-flex justify-content-between">
                        <h5>{{ $t('total') }}:</h5>
                        <h5>{{ currency }} {{ numberWithCommas(parseFloat(commission || 0) +
                          parseFloat(amount.replace(/,/g, '') || 0)) }}</h5>
                      </div>
                    </b-card>

                    <div class="d-flex justify-content-end">
                      <b-button
                        v-if="!pending"
                        variant="primary"
                        @click="payment"
                      >
                        {{ $t('payment') }}
                      </b-button>
                      <b-button
                        v-if="pending"
                        variant="primary"
                        disabled
                      >
                        <b-spinner
                          small
                          type="grow"
                        />
                        <span>{{ $t('loading') }}</span>
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    xl="12"
                    sm="12"
                    class="col-xxl-6"
                  >
                    <b-card
                      class="d-none d-xxl-block mt-2"
                      style="background-color: #f2f3f5;"
                    >
                      <feather-icon
                        icon="AlertTriangleIcon"
                        size="20"
                        class="text-warning mb-1"
                      />

                      <p>1. {{ $t('deposit-guide.desc1') }}
                      </p>
                      <p>2. {{ $t('deposit-guide.desc2') }}
                      </p>
                      <p>3. {{ $t('deposit-guide.desc3') }}
                      </p>
                      <p>4. {{ $t('forex-online-deposit-guide-desc4', { brand_name: getBrandName(),min_deposit:min_deposit,currency:currency }) }}
                      </p>
                      <p>5. {{ $t('deposit-guide.desc5') }}
                      </p>
                      <p>6. {{ $t('deposit-guide.desc6', { brand_name: getBrandName() }) }}
                      </p>

                    </b-card>
                    <b-card
                      class="d-none d-xxl-block mt-2"
                      style="background-color: #f2f3f5;"
                    >
                      <div>
                        <h5>
                          {{ $t('deposit-guide.desc7') }}
                        </h5>

                        <div class="d-flex justify-content-center mt-3">
                          <b-button
                            variant="primary"
                            :to="`/${$i18n.locale}/document/bank`"
                          >

                            {{ $t('fiatWidraw.buttontxt') }}
                          </b-button>
                        </div>
                      </div>
                    </b-card>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </div>
        <b-card :title="$t('lastTransactions')">
          <b-row align-h="center">
            <b-col cols="12">
              <b-table
                responsive
                striped
                hover
                sticky-header="1000px"
                :items="items"
                :fields="fields"
              >
                <template #cell(date)="data">
                  <span class="text-nowrap">
                    {{ getFormattedDate(data.item["date"]) }}

                  </span>
                </template>
                <template #cell(status)="data">

                  <b-button
                    :variant="data.item['status'] === 'successful' ? 'success' : data.item['status'] === 'unpaid' ? 'warning' : data.item['status'] === 'unsuccessful' ? 'danger' : ''"
                    size="sm"
                    style="display: block; width: 140px;"
                  >{{ $t('online-deposit' + '-' + data.item['status'])
                  }}</b-button>
                </template>
              </b-table>
            </b-col>
            <b-col cols="auto">
              <b-pagination
                v-if="totalTransactions > perPageTransactions"
                v-model="currentpageTransactions"
                :total-rows="totalTransactions"
                :per-page="perPageTransactions"
                align="fill"
                size="sm"
                class="mt-2 mb-1"
              />
            </b-col>
          </b-row>
        </b-card>
      </div>
    </b-overlay>
    <div v-else>
      this payment is disabled
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BAvatar,
  BSpinner,
  BOverlay,
  BPagination,
  BTable,
  BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import OnlinePaymentApis from '@/modules/crypto/services/apis/online-payment'
import { toEnglishDigits, numberWithCommas } from '@/utils'
import moment from '@/libs/moment'
import MetatraderApis from '@/modules/forex/service/apis'

const metatrader = new MetatraderApis()
const onlineDeposit = new OnlinePaymentApis()

export default {
  components: {
    BCard,
    BFormGroup,
    vSelect,
    BFormInput,
    BCol,
    BRow,
    BButton,
    BAvatar,
    BSpinner,
    BOverlay,
    BPagination,
    BTable,
    BForm,
  },
  data() {
    return {
      platform: [],
      platformSelected: '',
      accSelected: '',
      accounts: [],
      isHidden: true,
      currentpageTransactions: 1,
      totalTransactions: 0,
      perPageTransactions: 10,
      bankCard: [],
      amount: '',
      bank: '',
      cards: [],
      pending: false,
      show: false,
      commission: '',
      currency: '',
      min_deposit: '',
      paymentGatewayName: '',
      imgUrl: process.env.VUE_APP_BASE_IMAGE_URL,
      numberWithCommas,
      items: [],
      numbers: '',
      required,
    }
  },
  computed: {
    onlineBankDeposit() {
      return process.env.VUE_APP_IS_ENABLED_FOREX_BANK_ONLINE_DEPOSIT === 'true'
    },
    fields() {
      return [
        {
          label: this.$t('gateway'),
          key: 'gateway',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('currency'),
          key: 'currency',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('amount'),
          key: 'amount',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('date'),
          key: 'date',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('status'),
          key: 'status',
          tdClass: 'nameOfTheClass',

        },
      ]
    },
  },
  watch: {
    currentpageTransactions(val) {
      if (val) {
        this.getLastTransactions()
      }
    },
  },
  async mounted() {
    this.show = true
    await this.bankList()
    await this.gatewayList()
    await this.getLastTransactions()
    await this.getData()
    this.show = false
  },
  methods: {
    async getData() {
      // this.show = true
      await Promise.all([
        metatrader.getPlatforms().then(res => {
          this.platform = res.data.results
        }),
        metatrader.getAccounts().then(res => {
          this.accounts = res.data.results

          // eslint-disable-next-line prefer-destructuring
          this.accSelected = res.data.results[0]
          // eslint-disable-next-line prefer-destructuring
          if (res.data.results[0]) this.accountDetail = res.data.results[0]
          this.platformSelected = this.platform.find(
            plat => plat.value === this.accountDetail.platform,
          )

          if (this.$route.params.id) {
            const accountDetail = res.data.results.find(
              item => item.account === parseInt(this.$route.params.id, 10),
            )
            this.accSelected = accountDetail
            this.accountDetail = accountDetail
            this.platformSelected = this.platform.find(
              plat => plat.value === accountDetail.platform,
            )
          }
        }),
      ]).catch(err => {
        console.log(err)
        // this.show = false
      }).finally(() => {
        // this.show = false
      })
    },
    async getLastTransactions() {
      // this.show = true
      await onlineDeposit.lastTransactions({ current_page: this.currentpageTransactions, per_page: this.perPageTransactions }).then(res => {
        this.totalTransactions = res.data.results.paginate.total
        this.currentpageTransactions = res.data.results.paginate.current_page
        this.perPageTransactions = res.data.results.paginate.per_page
        this.items = res.data.results.data
      }).finally(() => {
        // this.show = false
      })
    },
    async bankList() {
      // this.show = true
      await onlineDeposit.getBankList({ currency: this.$route.params.token ? this.$route.params.token : undefined }).then(res => {
        const numbers = res.data.results.filter(item => {
          // eslint-disable-next-line no-param-reassign
          item.ibanCurrency = `${item.iban} (${item.currency.currency})`
          return item.check_status === 'success'
        })
        this.bankCard = numbers
        if (this.$route.params.token) {
          if (numbers) {
            // eslint-disable-next-line prefer-destructuring
            this.numbers = numbers[0]
          }
        }
      })
    },
    async gatewayList(currency) {
      // this.show = true
      await onlineDeposit.paymentGateway({ currency: currency || (this.$route.params.token ? this.$route.params.token : undefined) }).then(res => {
        this.cards = res.data.results
        const defaultGateway = this.cards.find(item => item.default)
        if (defaultGateway) {
          this.getewaySelected = true
          this.currency = defaultGateway.currency
          this.commission = defaultGateway.commission
          this.paymentGatewayName = defaultGateway.name
          this.min_deposit = defaultGateway.min_deposit
        } else {
          this.getewaySelected = false
          this.commission = '0'
          this.paymentGatewayName = ''

          if (this.$route.params.token) {
            this.currency = this.$route.params.token
          }
        }
      }).finally(() => {
        // this.show = false
      })
    },
    selectCard(item) {
      // eslint-disable-next-line array-callback-return
      this.cards.map(card => {
        // eslint-disable-next-line no-param-reassign
        card.default = false
      })
      // eslint-disable-next-line no-param-reassign
      item.default = true
      this.getewaySelected = true
      this.currency = item.currency
      this.commission = item.commission
      this.min_deposit = item.min_deposit
      this.paymentGatewayName = item.name
    },
    numberFormat(value) {
      return this.numberWithCommas(value)
    },
    async payment() {
      const valid = await this.$refs.simpleRules.validate()
      if (valid) {
        this.pending = true
        await onlineDeposit.savePaymentGateway({
          type: 'forex', account: this.accSelected.account, platform: this.platformSelected.value, gateway: this.paymentGatewayName, amount: toEnglishDigits(this.amount.replace(/,/g, '')),
        }).then(res => {
          window.location.replace(res.data.results.url)
        }).catch(err => {
          this.pending = false
          console.log(err)
        })
      }
    },
    getFormattedDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
  },
}
</script>
  <style scoped>
  .cardSelected {
    background-color: #f2f3f5;
  }

  .nameOfTheClass {
    padding: 20px !important;
  }

  [dir=rtl] .right-selector::v-deep ul li {
    direction: rtl !important;
  }
  </style>
