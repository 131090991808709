import axiosIns from '@/libs/axios'

export default class OnlinePaymentApis {
    getBankList = async data => await axiosIns.get('document/bank', { params: data })

    paymentGateway = async data => await axiosIns.get('paymentgateway', { params: data })

    savePaymentGateway = async data => await axiosIns.post('paymentgateway/pay', data)

    lastTransactions = async data => await axiosIns.get(`paymentgateway/payments?page=${data.current_page}&per_page=${data.per_page}`)
}
